import _export from "../internals/export";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _arrayIncludes from "../internals/array-includes";
import _arrayMethodIsStrict from "../internals/array-method-is-strict";

/* eslint-disable es-x/no-array-prototype-indexof -- required for testing */
var $ = _export;
var uncurryThis = _functionUncurryThis;
var $IndexOf = _arrayIncludes.indexOf;
var arrayMethodIsStrict = _arrayMethodIsStrict;
var un$IndexOf = uncurryThis([].indexOf);
var NEGATIVE_ZERO = !!un$IndexOf && 1 / un$IndexOf([1], 1, -0) < 0;
var STRICT_METHOD = arrayMethodIsStrict("indexOf"); // `Array.prototype.indexOf` method
// https://tc39.es/ecma262/#sec-array.prototype.indexof

$({
  target: "Array",
  proto: true,
  forced: NEGATIVE_ZERO || !STRICT_METHOD
}, {
  indexOf: function indexOf(searchElement
  /* , fromIndex = 0 */
  ) {
    var fromIndex = arguments.length > 1 ? arguments[1] : undefined;
    return NEGATIVE_ZERO // convert -0 to +0
    ? un$IndexOf(this, searchElement, fromIndex) || 0 : $IndexOf(this, searchElement, fromIndex);
  }
});
export default {};